/* hybrid  */
/* see also: classic dark  */
.hljs-comment,
.hljs-quote {
  color: #696969;
}

/* Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: #d91e18;
}

/* Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
  color: #aa5d00;
}

/* Yellow */
.hljs-attribute {
  color: #aa5d00;
}

/* Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #008000;
}

/* Blue */
.hljs-title,
.hljs-section {
  color: #007faa;
}

/* Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #7928a1;
}

.hljs {
  display: block;
  overflow-x: auto;
  color: #545454;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

/**
 * HLJS
 */

.hljs,
code[class] {
  display: block;
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-code);
  line-height: 1.5;
  overflow-x: auto;
  position: relative;
}

.fenced-code {
  border-color: var(--color-border);
  border-style: solid;
  box-shadow: 8px 8px #00000033;
  font-family: var(--font-family-monospace);
  margin: 20px 0;
  padding: 20px;
  position: relative;

  @media screen and (max-width: 1023px) {
    border-width: 1px 0 1px 0;
  }
  @media screen and (min-width: 1024px) {
    border-width: 1px;
  }

  .fenced-code__filename {
    font-size: var(--font-size-code);
    border-bottom: 1px solid var(--color-border);
    margin-bottom: 10px;
    overflow-x: auto;
    padding-bottom: 10px;
    width: 100%;
  }

  .fenced-code__filename + .fenced-code__language-name {
    display: none;
  }

  .fenced-code__language-name {
    background-color: var(--color-body-background);
    content: attr(data-language-name);
    font-size: var(--font-size-smaller);
    border-color: var(--color-border);
    border-style: solid;
    font-family: var(--font-family-monospace);
    pointer-events: none;
    user-select: none;
    z-index: 1;

    @media screen and (max-width: 1023px) {
      border-width: 0 0 1px 0;
      padding: 10px;
    }
    @media screen and (min-width: 1024px) {
      border-top-right-radius: 4px;
      border-width: 0 0 1px 1px;
      padding: 10px 20px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

/**
 * Graphviz
 */

.fenced-graphviz {
  display: none;
}

.fenced-graphviz.fenced-graphviz--rendered {
  display: table;
  width: 100%;
  outline: 1px solid var(--color-border);
  overflow-x: auto;
  table-layout: fixed;
}

.fenced-graphviz__scene {
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 24px;
  text-align: center;
}
